import type { Auth, Guest, Profile, User } from '~/models';

import { useNuxtApp } from '#app';

const MASTER_PROFILE: Profile = {
  fullName: 'Master',
  stripeId: '',
  uuid: '',
  user: {
    username: 'master',
    email: 'master@foodinn.ie',
    isDisabled: false
  },
  userId: 1
} as const;

interface AuthState {
  accessToken: string;
  username: string;
  remember: string;
}

const STORE_NAME = 'customer-auth';

const useAuthStore = defineStore(STORE_NAME, () => {
  const { $http } = useNuxtApp();

  const defaultState = (): AuthState => ({
    accessToken: '',
    username: '',
    remember: ''
  });

  const state = useCookie<AuthState>(STORE_NAME, {
    path: '/',
    sameSite: 'strict',
    maxAge: 60 * 60 * 24 * 7,
    watch: true,
    decode: (value) => JSON.parse(decode(value)),
    encode: (value) => encode(JSON.stringify(value)),
    default: defaultState
  });

  const guest = ref<Guest | null>(null);
  const profile = ref<Profile | null>(null);

  const handleLogin = async (
    username: string,
    auth: Auth | null
  ): Promise<void> => {
    if (!auth) {
      return;
    }

    profile.value =
      username === 'master'
        ? MASTER_PROFILE
        : await $http.profile.getByUsername(username);

    if (!profile.value) {
      return;
    }

    state.value.accessToken = auth.accessToken;
    state.value.username = profile.value.user.username;

    guestLogout();
  };

  const changePassword = (
    currentPass: string,
    newPass: string
  ): Promise<void> => {
    return $http.auth.changePassword(currentPass, newPass);
  };

  const confirmEmail = async (email: string, code: string): Promise<void> => {
    const auth = await $http.auth.confirmEmail(email, code);
    await handleLogin(email, auth);
  };

  const forgotPassword = (email: string): Promise<void> => {
    return $http.auth.forgotPassword(email);
  };

  const forgotPasswordGet = async (uuid: string): Promise<string> => {
    return (await $http.auth.forgotPasswordGet(uuid)) ?? '';
  };

  const guestLogin = async (email: string): Promise<void> => {
    guest.value = await $http.auth.guestLogin({
      email: email,
      name: email
    });
  };

  const guestLogout = (): void => {
    guest.value = null;
  };

  const login = async (username: string, password: string): Promise<void> => {
    const auth = await $http.auth.login(username, password);
    await handleLogin(username, auth);
  };

  const logout = (): void => {
    state.value.accessToken = '';
    state.value.username = '';
    profile.value = null;
  };

  const refreshProfile = async (): Promise<void> => {
    if (!state.value.accessToken || !state.value.username) {
      logout();
      return;
    }

    profile.value = await $http.profile.getByUsername(state.value.username);
  };

  const register = (user: User): Promise<User | null> => {
    return $http.auth.register(user);
  };

  const resendConfirmEmail = async (email: string): Promise<void> => {
    await $http.auth.resendConfirmEmail(email);
  };

  const resetPassword = (email: string, newPass: string): Promise<void> => {
    return $http.auth.resetPassword(email, newPass);
  };

  const setRemember = (payload: string): void => {
    state.value.remember = payload;
  };

  return {
    accessToken: computed<string>(() => state.value.accessToken),
    remember: computed<string>(() => state.value.remember),
    profile,
    guest,
    changePassword,
    confirmEmail,
    forgotPassword,
    forgotPasswordGet,
    guestLogin,
    guestLogout,
    login,
    logout,
    refreshProfile,
    register,
    resendConfirmEmail,
    resetPassword,
    setRemember,
    isAuthenticated: computed<boolean>(() => !!state.value.accessToken),
    isGuest: computed<boolean>(() => !!guest.value)
  };
});

export { useAuthStore };
