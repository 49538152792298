import CryptoJS from 'crypto-js';

const ENCRYPT_KEY = 'e55e4c83-1df5-4791-947d-970c90aedf02';

const encode = (value: string): string => {
  const ciphertext = CryptoJS.AES.encrypt(value, ENCRYPT_KEY);
  return ciphertext.toString();
};

const decode = (value: string): string => {
  const bytes = CryptoJS.AES.decrypt(value, ENCRYPT_KEY);
  const originalString = bytes.toString(CryptoJS.enc.Utf8);
  return originalString;
};

export { encode, decode };
