import revive_payload_client_wuw1UzHmKO from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VtMeTiZxar from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8yD2Dj85oN from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_lr9Xg1LViY from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.3_@unocss+reset@0.61.0_floating-vue@5.2.2_@nuxt+ki_ljshmxdckq2ttnleljglsu2gsm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_fveQlmDX4t from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yFn612fhsf from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_SfKk2iIiMl from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.4.5_vue@3.4.29_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Ry2G9Jcest from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_VDpkoUEPsh from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@lezer+common@1.2.1_@nuxt+devtools@1.3.3_@unocss+reset@0.61.0_floatin_5cj75ols4ih2nscr6dbtwhr6yu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_hWaI4TNzVD from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@lezer+common@1.2.1_@nuxt+devtools@1.3.3_@unocss+reset@0.61.0_floatin_5cj75ols4ih2nscr6dbtwhr6yu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_60xvCG2edh from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_@nuxt+devtools@1.3.3_@unocss+reset@0.61.0_floating-vue@5.2.2__xbpvomgbkixtjyfiqamgusdlvy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_RRpuVYcOEH from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_@nuxt+devtools@1.3.3_@unocss+reset@0.61.0_floating-vue@5.2.2__xbpvomgbkixtjyfiqamgusdlvy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_2dkn1uoJKV from "/app/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_b6jcvlpon33lsmhxzcz4dohr54/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_GkoVrXOaYh from "/app/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_b6jcvlpon33lsmhxzcz4dohr54/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_JdOi9fiBNE from "/app/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_b6jcvlpon33lsmhxzcz4dohr54/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_6k3Azp2mCW from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import pwa_icons_Yxs6WkqfaC from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@types+node@20.14.3_sass@1.77.6__jtd2h6tclmgf3ndoyopp4uqdz4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_9i8X94tlzu from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@types+node@20.14.3_sass@1.77.6__jtd2h6tclmgf3ndoyopp4uqdz4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_dBLwxDLlTU from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_1JT954OBfZ from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_8CcCirWtnE from "/app/plugins/directives.ts";
import services_eN9fWZLDQ0 from "/app/plugins/services.ts";
export default [
  revive_payload_client_wuw1UzHmKO,
  unhead_VtMeTiZxar,
  router_8yD2Dj85oN,
  _0_siteConfig_lr9Xg1LViY,
  payload_client_fveQlmDX4t,
  check_outdated_build_client_yFn612fhsf,
  plugin_vue3_SfKk2iIiMl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ry2G9Jcest,
  titles_VDpkoUEPsh,
  defaults_hWaI4TNzVD,
  siteConfig_60xvCG2edh,
  inferSeoMetaPlugin_RRpuVYcOEH,
  slideovers_2dkn1uoJKV,
  modals_GkoVrXOaYh,
  colors_JdOi9fiBNE,
  plugin_client_6k3Azp2mCW,
  pwa_icons_Yxs6WkqfaC,
  pwa_client_9i8X94tlzu,
  plugin_dBLwxDLlTU,
  chunk_reload_client_1JT954OBfZ,
  directives_8CcCirWtnE,
  services_eN9fWZLDQ0
]