import type { $Fetch } from 'nitropack';

import type { Discount } from '~/models';

import type { DiscountType } from '~/models/discount';

interface Filter {
  startDate?: string;
  endDate?: string;
  productUuid?: string;
  voucherCode?: string;
  discountType?: DiscountType;
  onlyActive?: boolean;
  takeawayOption?: string;
}

export default class DiscountService {
  constructor(private fetch: $Fetch) {}

  async get(uuid: string): Promise<Discount> {
    return await this.fetch<Discount>(`/discounts/${uuid}`);
  }

  async voucher(filter: Filter): Promise<Discount> {
    return await this.fetch<Discount>('/discounts/voucher', {
      query: filter
    });
  }
}

export type { Filter };
